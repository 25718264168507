import request from "@/http/api";

export function getCouponsInfo(activityNo) {
    return request({
        url: `/api/coupons/${activityNo}`,
        method: "put",
        data: { activityNo }
    })
}


export function getCouponsDetail(activityNo) {
    return request({
        url: `/api/coupons/${activityNo}`,
        method: "get"
    })
}