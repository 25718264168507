<template>
  <div class="coupons-wrap bg-white">
    <div>
      <div class="coupons-content margin-top" :class="isMobileDevices ? '' : 'solid'">
        <div>
          <div
            class="
              solid
              bg-gradual-orange
              padding
              radius
              text-center
              shadow-blur
            "
          >
            <div class="price-number text-lg">
              <div v-if="coupons.couponType === 0">
                立减券 <span class="text-bold">{{ coupons.price }}</span
                >元
              </div>
              <div v-if="coupons.couponType === 1">
                满
                <span class="text- text-bold">{{ coupons.threshold }}</span> 减
                <span class="text- text-bold">{{ coupons.price }}</span>
              </div>
              <div v-if="coupons.couponType === 2">
                折扣券 <span class="text-">{{ coupons.threshold }}</span> 折
              </div>
            </div>

            <div class="margin-top-sm text-lg">
              {{ couponType }}
              <span v-if="term.durationInDay" class="text-xs margin-left-xs"
                >有效期 {{ term.durationInDay }}天</span
              >
            </div>
          </div>
        </div>
        <div class="margin-tb-sm text-red">
          <div v-if="isGet === 'complete'" class="text-green">
            领取成功！优惠券将于5~10分钟后，发送到您的账户里，请注意查收哟~
          </div>
          <div v-if="errorText">
            <div>{{ errorText }}</div>
          </div>
        </div>
        <div class="margin-tb-sm" v-if="isGet === 'load'">
          <button
            @click="getCoupons"
            class="bg-yellow cu-btn padding-xs"
            style="width: 100%"
          >
            立即领取
          </button>
        </div>
        <div class="notice-list">
          <p>活动名称：{{ coupons.activityName }}</p>
          <p>活动规则：{{ limitUser }}</p>
          <p>活动时间：{{ term.from | dayValue }} - {{ term.to | dayValue }}</p>
        </div>
        <div class="margin-top">
          <a class="text-blue" href="/">< 返回首页</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCouponsInfo, getCouponsDetail } from "./service";
import moment from "moment";
export default {
  data() {
    return {
      errorText: "",
      coupons: {},
      isGet: "load",
      isGetTime: null,
      params: {
        phone: "",
        code: "",
      },
    };
  },
  filters: {
    dayValue(value) {
      if (!value) return "-";
      return moment(value).format("YYYY年MM月DD日 HH:mm");
    },
  },
  created() {
    this.getCouponsDetail();
  },
  computed: {
    isMobileDevices() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
        navigator.userAgent
      );
    },
    activityNo() {
      return this.$route.params.id;
    },
    term() {
      return this.coupons.term || {};
    },
    limitUser() {
      if (this.coupons.customerType === 1) return "仅限普通用户可领取";
      if (this.coupons.customerType === 2) return "仅限协议VIP用户领取";
      return "所有用户均可领取";
    },
    couponType() {
      if (this.coupons.couponType === 0) return "立减券";
      if (this.coupons.couponType === 1) return `满减券`;
      if (this.coupons.couponType === 2) return "折扣券";
    },
    user() {
      return this.$store.getters.user || {};
    },
  },
  methods: {
    async getCouponsDetail() {
      const { data } = await getCouponsDetail(this.activityNo);
      this.coupons = data;
    },
    async getCoupons() {
      try {
        if (!this.user.mobile) {
          if (!this.params.phone || !this.params.code) {
            this.$message.info("请验证手机号");
          }
          return;
        }
        this.isGet = false;
        await getCouponsInfo(this.activityNo);
        this.isGet = "complete";
      } catch (error) {
        this.errorText = error.errmsg;
        this.isGet = "error";
      }
    },

    backHome() {
      location.href = "/";
    },
  },
};
</script>

<style lang="scss" scoped>
.coupons-wrap {
  display: flex;
  justify-content: center;
  height: 100vh;
  .coupons-content {
    padding: 20px;
    color: #000;
    font-size: 14px;
    .notice-list {
      line-height: 2;
    }
  }
  .price-number {
    font-size: 30px;
  }
  .cu-btn {
    cursor: pointer;
  }
}
</style>